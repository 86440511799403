/* CSS FOR MEGAMENU */
.container > .bc_menu_collapse{	
	@media (max-width: 991px) {
	margin: 0 -15px; 
	}
}
#header > .mega-menu {
	position: relative;
	background: $colortheme;
	@media (max-width: 991px) {
		.container {
			max-width: 720px;
			overflow: visible;
		}
	}
	@media (max-width: 767px) {
		height: 0;
		margin-top: -69px;
		nav {
			display: none;
		}	
	}
	.menu-sticky{
		//float: left;
	}
}
.bc-menu-container {
		
	.title-menu-mobile {
		display: none;
		width: 40px;
		height: 40px;
		background: $bkgMenuHover;
		font-size: 0;
		text-align: center;
		line-height: 40px;
		color: #fff;
		cursor: pointer;
		&:hover {
			background: $bkgMenu;
		}
		&:before {
			content: "\f0c9";
			font-family: "FontAweSome";
			font-size: 18px;
		}
		@media (max-width: 767px) {
			display: inline-block;
			position: absolute;
			left: 15px;
			top: 0;
		}
	}
		
	.menu-content {
		height: 60px;
		margin-bottom: 0;

		img {
			width: 100%;
		}

		>li {
			display: inline-block;
			float: left;
			color: #fff;
			text-transform: uppercase;
			height: 100%;
			font-size: 0;				
			-webkit-transition: all .5s;
			-o-transition: all .5s;
			transition: all .5s;
			position: relative;

			a {
				@include fontStyle_3();
				display: block;
			}

			> a {
				height: 100%;
				line-height: 60px;
				padding: 0 26px;
				-webkit-user-select: none;
				-webkit-touch-callout: none;
				pointer-events: auto;
				@media (max-width: 991px) {
					padding: 0 20px;
				}
			} 

			&:hover {
				background: $bkgMenu;

				> a {
					color: #fff;

					&:after {
						color: #fff;
					}
				}
			}
		}
	}
}
.bc-menu-container  {
/*Share properties between horizontal menu and vertical menu*/
	.dropdown {
		display: none;
		padding-top: 20px;
		padding-bottom: 20px; 
		background: #fff;
		-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
	}
	.content-drop {
		@include clearfix();
		padding-left: 0;
		padding-right: 0;
	}
	.menu-column {
		padding: 0 25px;
		@media (max-width: 991px) {
			padding: 0 20px;
		}
	}
	.column-item {
		@include clearfix();
		color: $bkgMenuHover;

		li:hover {

			> .icon-drop-mobile {
				color: $bkgMenu;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}

			> .column-item  {
				visibility: visible;
				opacity: 1;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}
		}

		a {
			padding: 10px 0;
			line-height: normal;
			text-transform: none;
			font-family: $fontText;

			&:hover {
				color: $bkgMenu;
			}
		}

		h3 {
			@include fontStyle_3();
			margin-top: 0;
			margin-bottom: 15px;
		}

		.parent {
			position: relative;

			.icon-drop-mobile {
				position: absolute;
				right: 0;
				top: 12px;

				&:before {
					content: "\f105";
					display: block;
					font-size: 15px;
				}
			}

			.column-item {
				position: absolute;
				left: calc(100% + 25px);
				top: 0;
				min-width: 195px;
				background: #fff;
				padding: 0 25px;
				visibility: hidden;
				opacity: 0;
				-webkit-box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
				box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
				-webkit-transform: translate3d(0,0,0);
				-ms-transform: translate3d(0,0,0);
				-o-transform: translate3d(0,0,0);
				transform: translate3d(0,0,0);
				&:before {
					content:"";
					display: block;
					width: 30px;
					height: 100%;
					position: absolute;
					left: -30px;
					top: 0;	
				}

				@media (max-width: 991px) {
					min-width: 140px;
					left: calc(100% + 20px);
				}
			}
		}
	}
	.menu-item {
		&:hover {
			.dropdown {
				display: block;
			}
		}
		h3 {
			padding-bottom: 10px;
			border-bottom: 1px dotted $bkgMenuHover;
		}
		.ajax_block_product {
			.left-block {
				.product_img_link {
					padding: 0;
				}
			}
			.right-block {
				.product-name {
					margin-bottom: 0;
					margin-top: 0;
					a {
						text-transform: uppercase;	
						font-size: em(12px);					
					}
				}
				.content_price {
					.product-price {
						font-size: em(10px); 
					}
				}
			}	
		}
		&.menu-v1 {
			.content-drop {
				overflow: hidden;
			}
			.column-item {
				padding-bottom: 1000px;
				margin-bottom: -1000px;
				border-right: 1px dotted $bkgMenuHover;
				padding-right: 25px;
			}
			.menu-column {
				padding-right: 0;
				&:last-child {
					.column-item {
						border: none;
					}
				}
			}	
		}
		&.menu-v2 {
			.ajax_block_product {
				.left-block {
					width: 100%;
					margin: 0;
				}
				.right-block {
					width: 100%;
				}	
			}
			.parent {
				.icon-drop-mobile { 
					display: none;
				}
				.column-item {
					position: relative;
					left: 0;
					top: 0;
					visibility: visible;
					opacity: 1;
					padding-left: 0;
					min-width: 0;  
					white-space: nowrap;
					-webkit-box-shadow: none;
					box-shadow: none;
	
					li {
						a {
							padding: 5px 0;
							font-size: em(12px);
							padding-left: 10px;
							@media (max-width: 991px) {
								padding-left: 0;
							}
							
							&:before {
								content: "\f178";
								font-family: "FontAweSome";
								color: inherit;	
								display: inline-block;
								margin-right: 5px;			
							}
							&:hover{
								color: $bkgMenu;
								padding-left: 15px;
								@media (max-width: 991px) {
									padding-left: 0;
								}
							}

						}
					}
				}	
			}	
		}
		&.menu-v3 {
			img {
				margin-bottom: 20px;
			}	
		}
		&.menu-v4 {
			.content-drop {
				overflow: hidden;
			}
			.column-item {
				padding-bottom: 1000px;
				margin-bottom: -1000px;
				padding-right: 25px;
				border-right: 1px dotted $bkgMenuHover;
			}
			.menu-column {
				padding-right: 0;
				&:last-child {
					.column-item {
						border: none;
					}	
				}
			}
		}
	}
/*Horizontal Menu unique props*/	
	&.megamenu_top {		
		/*grid*/
		.col-sm-12 {
			width: 1170px;
			@media(max-width: 1199px)
			{
				width: 970px;
			}
			@media(max-width: 991px)
			{
				width: 720px;
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-11 {
			width: menu-grid(1170,11,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,11,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,11,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-10 {
			width: menu-grid(1170,10,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,10,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,10,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-9 {
			width: menu-grid(1170,9,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,9,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,9,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-8 {
			width: menu-grid(1170,8,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,8,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,8,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-7 {
			width: menu-grid(1170,7,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,7,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,7,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-6 {
			width: menu-grid(1170,6,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,6,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,6,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-5 {
			width: menu-grid(1170,5,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,5,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,5,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-4 {
			width: menu-grid(1170,4,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,4,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,4,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-3 {
			width: menu-grid(1170,3,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,3,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,3,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-2 {
			width: menu-grid(1170,2,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,2,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,2,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-1 {
			width: menu-grid(1170,1,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(970,1,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(720,1,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
				
		.dropdown {
			position: absolute;
			top: 60px;
			width: auto;
			left: 0;
			z-index: 100;
		}

		.menu-item.menu-parent {
			/*&:before {
				content:'';
				display: none;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 10px 10px 10px;
				border-color: transparent transparent $bkgMenu transparent;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -10px;
			}*/
			&:after {
				/*content:'';
				display: none;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 5px 5px;
				border-color: transparent transparent #fff transparent;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -5px;
				z-index: 100;*/
			}
			&:hover {
				&:before, &:after {
					display: block;
				}
			}	
		}
		
	}
/*Vertical menu unique props*/
	&.menu_vertical {
		@media (max-width: 1199px) {
			.container {
				overflow: visible;
				position: relative;
			}	
		}
		@media (max-width: 767px) {
			display: none;
		}
		/*grid*/
		.col-sm-12 {
			width: 893px;
			@media(max-width: 1199px)
			{
				width: 742px;
			}
			@media(max-width: 991px)
			{
				width: 555px;
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-11 {
			width: menu-grid(893,11,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,11,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,11,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-10 {
			width: menu-grid(893,10,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,10,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,10,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-9 {
			width: menu-grid(893,9,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,9,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,9,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-8 {
			width: menu-grid(893,8,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,8,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,8,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-7 {
			width: menu-grid(893,7,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,7,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,7,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-6 {
			width: menu-grid(893,6,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,6,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,6,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-5 {
			width: menu-grid(893,5,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,5,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,5,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-4 {
			width: menu-grid(893,4,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,4,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,4,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-3 {
			width: menu-grid(893,3,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,3,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,3,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-2 {
			width: menu-grid(893,2,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,2,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,2,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}
		.col-sm-1 {
			width: menu-grid(893,1,12);
			@media(max-width: 1199px)
			{
				width: menu-grid(742,1,12);
			}
			@media(max-width: 991px)
			{
				width: menu-grid(555,1,12);
			}
			@media(max-width: 767px)
			{
				width: 100%;
			}
		}

		.menu-content {

			.menu-item {
				position: relative !important;
				&:hover {
					.dropdown {
						display: block;
					}
					
				}
				&.menu-v2, &.menu-v3, &.menu-v4 {
					position: static;
				}
				&:before{
					display: inline-block;
					font-family: $fonticont;
					font-size: 14px;
					position: absolute;
					left: 2px;
					top: 10px;
					color: $colortheme1;
					width: 30px;
					height: 30px;
					line-height: 30px;
					text-align: center;
				}
				@media (max-width: 991px) {
					a{
						padding: 0 10px 0 30px !important;
					}
					&:before{
						line-height: 20px;
					}
				}
			}			
		}

		.dropdown {
			position: absolute;
			left: 100%;
			top: 0;
			padding-top: 20px;
			padding-bottom: 20px;
			z-index: 100;
			margin-left: 0 !important;
			-webkit-box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
			box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
		}

			
	}
}

/*Mobile menu*/
.mobile-version.visible-xs.visible-sm {
	/*temporary hide mobile menu on tablet layout*/
	@media (min-width: 768px) {
		display: none !important;
	}
}

.menu_mobile {
	width: 280px;
	height: 100%;
	background: $bkgMenuHover;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10000;
	overflow: auto;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	-webkit-transform: translate(-280px,0);
	-ms-transform: translate(-280px,0);
	-o-transform: translate(-280px,0);
	transform: translate(-280px,0);
	&.opened {
		-webkit-transform: translate(0,0);
		-ms-transform: translate(0,0);
		-o-transform: translate(0,0);
		transform: translate(0,0);
	}
	.container {
		padding-left: 0;
		padding-right: 0;
	}
	.menu-content {
		height: auto;
		@include clearfix();
		padding: 0 30px;
		> li {
			display: block;
			width: 100%;
			border-bottom: 1px dotted grey;
			color: #fff;
			.dropdown {
				clear: both;
				padding-top: 0;
				background: transparent;
				box-shadow: none;
			}
			.menu-column{
				padding: 0;
			}
			.column-item {
				position: relative;
				left: 0;
				top: 0;
				opacity: 1;
				padding: 0;
				visibility: visible;
				min-width: 0;
				padding-left: 10px;
				-webkit-transition-duration: 0s;
				-o-transition-duration: 0s;
				transition-duration: 0s;
				color: #fff;
				background: transparent;
				box-shadow: none;
				li {
					.column-item {
						display: none;
					}	
				}
			}
			.icon-drop-mobile {
				cursor: pointer;
				position: absolute;
				right: 15px;
				top: 6px;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: right;
				z-index: 10;
				&:before {
					content: "\f0d7";
					font-family: "FontAweSome";
					font-size: 13px;
					color: #fff;
				}
				&.opened {
					&:before {
						content: "\f0d8";  
					}	
				}
			}
			a {
				color: inherit;
				&:hover {
					color: $bkgMenu;
				}
			}
			> a {
				line-height: 40px;
				display: block;
				float: left;
				width: 100%;
				padding: 0;
			}
			&:last-child {
				border: none;
			}
			&:hover {
				.dropdown {
					display: none;
				}
			}
			&:hover{
				background: none;
			}
		}
	}
	.title-menu {
		background: $bkgMenu;
		text-align: center;
		border-bottom: none;
		span {
			@include fontStyle_6();
			color: #fff;
			text-transform: uppercase;
			padding: 10px 15px;
			display: block;
		}
	}
	nav {
		border-top: none;
		margin-left: 0;
		margin-right: 0;
	}
}