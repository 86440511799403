/*==============================CSS FOR CATEGORY PAGE==============================*/
/* BREADCRUMB */
.top-breadcrumb {
	background: url('../images/bg-promotion.png') no-repeat center;
	background-color: rgba(0,0,0,0.9);
	background-size: cover;	
	@media (max-width: 991px) {
		.container {
			padding-left: 15px;
			padding-right: 15px;	
		}
		
	}

	.breadcrumb-title {
		@include clearfix(); 
		padding-top: 85px;
		padding-bottom: 85px;
		@media (max-width: 991px) {
			padding-top: 50px; 
			padding-bottom: 50px; 
		}
		@media (max-width: 767px) {
			padding-top: 25px; 
			padding-bottom: 25px; 
		}

		h1 {
			display: inline-block;
			float: left;
			width: 50%;
			text-transform: uppercase;
			color: #fff;
			font-weight: normal;
			margin: 0;
			line-height: 1;
			@media (max-width: 991px){
				width: 100%;
				text-align: center;
			}
		}

		.breadcrumb {
			display: inline-block;
			float: right;
			width: 50%;
			margin: 0;
			border: none;
			border-radius: 0;
			background-color: transparent;
			text-align: right;
			text-shadow: none;
			@include fontStyle_3();
			font-weight: bold;
			color: #fff;
			a {
				color: #fff;
			}
			@media (max-width: 991px){
				width: 100%;
				padding-top: 5px;
				text-align: center;
			}

			>span {
				a {
					padding-right: 10px;
				}
			}

			.navigation-pipe {
				width: auto;
				line-height: 1;
				display: inline-block;
				position: relative;
				top: 5px;
				vertical-align: top;
				font-size: 0;
				text-align: center;
				text-indent: 0;
				padding-right: 10px; 

				&:before {
					content: "\f105";
					font-family: "FontAweSome";
					display: inline-block;
					font-size: 14px;
				}
			}

			.navigation_page {
				display: inline;
				margin-left: -5px;
				span {
					display: inline-block;
				}
				a {
					margin-left: 0;
					padding-right: 10px;
				}
				.navigation-pipe {
					padding-right: 10px;
				}
			}

			a {
				background: transparent;
				width: auto;
				font-weight: normal;
				margin-right: 0;
				padding: 0;
				display: inline-block;
				&:before, &:after {
					display: none;
				}
				&:hover {
					color: $bkgMenu;
				}
				&.home {
					font-size: 0;
					width: 20px;
					height: 20px;
					text-align: center;
					&:before {
						content: "\f015";
						display: block;
						font-family: "FontAweSome";
						font-size: em(14px);
						color: #fff;	
						position: relative;
						left: 0; top: 0;
						-webkit-transform: rotate(0deg);
						-ms-transform: rotate(0deg);
						-o-transform: rotate(0deg);
						transform: rotate(0deg);
						-webkit-transition: all .35s;
						-o-transition: all .35s;
						transition: all .35s;
						text-indent: 0;
						margin-right: 0;
					}
					&:hover:before {
						color: $bkgMenu;
					}	
				}
			}

			> a, > span {
				@include fontStyle_3();
				vertical-align: top;
				display: inline-block;
				color: inherit;
			}
		}
	}
}

/* CATEGORY BLOCK LEFT */
#left_column {

	.content_left {
		background: #fefefe;
		border: 1px solid #f9f9f9;
		padding: 30px 15px;
		@media (max-width: 767px) {
			border: none;
			background: none;
			padding: 20px 0;
		}
	}

	div.checker {
		top: -1px;
	}

	.layered_filter {
		border: none;
	}

	.layered_price {
		>ul {
			overflow: visible;	
		}
	}

	.color-group {

		li {
			position: relative;

			&:after {
				content: "";
				width: 8px;
				height: 8px;
				border: 2px solid #fff;
				display: block;
				position: absolute;
				top: 7px;
				left: 2px;
			}

			.color-option {
				margin-right: 5px;
			}
		}
	}
	
	.title_block {
		background: none;
		border: none;
		@include fontStyle_7();
		padding: 15px 0;
		line-height: 1;
		position: relative;
		color: $colorHeading;
		&:after {
			content: "";
			display: block;
			height: 2px;
			width: 50px;
			background: $colorHeading;
			position: absolute;
			top: auto;
			left: 0;
			bottom: 0;
			color: inherit;
		}
		@media (max-width: 767px) {
			padding-top: 0px;
			cursor: pointer;
			&:before {
				content: "\f205";
				font-family: "FontAweSome";
				font-size: 14px;
				display: block;
				position: absolute;
				right: 0;
				top: 3px;
				color: inherit;
			}
			&.active {
				&:before {
					content: "\f204";	
				}
			}
			&:hover, &.active {
				color: $bkgMenu;
				&:after {
					background: $bkgMenu;
				}
				a {
					color: $bkgMenu;	
				}
			}
		}
	}

	#categories_block_left {

		.title_block {
			margin-top: 0;
			padding-top: 0;
		}

		ul {
			border: none;
		}

		li {

			a {
				padding: 20px 0 20px 0;
				color: $colorHeading;
				font-weight: normal;
				border-color: #eee;
				line-height: 1;

				&:hover, &.selected {
					background: none;
					color: $bkgMenu;
				}
			}

			li {

				a {
					padding-left: 15px;
				}

				a:before {
					line-height: 1;
				}
			}

			span.grower {
				background: none;
				font-size: 18px;
				top: 8px;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: right;

				&:before {
					content:"\f18e";	
					display: inline-block;	
					color: #d2d2d2;
					text-align: right;
					width: 18px;
					height: 18px;
					line-height: normal;
					-webkit-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
					-webkit-transform-origin: 50% 50%;
					-moz-transform-origin: 50% 50%;
					-ms-transform-origin: 50% 50%;
					-o-transform-origin: 50% 50%;
					transform-origin: 50% 50%;
					
				}

				&.OPEN {
					
					&:before {
						content:"\f18e";
						-webkit-transform: rotate(90deg);
						-ms-transform: rotate(90deg);
						-o-transform: rotate(90deg);
						transform: rotate(90deg);
						color: $bkgMenu;
					}

					+ a {
						color: $bkgMenu;
					}
				}

				&:hover {

					&:before {
						content:"\f18e";
						color: $bkgMenu;
						-webkit-transform: rotate(90deg);
						-ms-transform: rotate(90deg);
						-o-transform: rotate(90deg);
						transform: rotate(90deg);
					}

					+ a {
						background: none;
						color: $bkgMenu;	
					}					
				}
			}
		}	
	}

	#layered_block_left {

		.title_block {
			display: none;
			@media (max-width: 767px) {
				display: block;
			}
		}

		#enabled_filters {
			padding: 0;
			border: none;
			background: none;

			ul {
				padding-top: 0;
			}
		}

		.layered_subtitle  {
			@include fontStyle_7();
			font-weight: normal;
			padding: 15px 0;
			line-height: 1;
			position: relative;
			color: $colorHeading;
			text-transform: uppercase;
			margin-bottom: 20px;
			&:after {
				content: "";
				display: block;
				height: 2px;
				width: 50px;
				background: $colorHeading;
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}	
	}	

	.blog_lastest_posts {
		h3 {
			font-size: em(12px);
			text-transform: uppercase;
			color: $bkgMenuHover;	
			margin-bottom: 15px;
		}	
	}

	.blog_comments {
		h3 {
			font-size: em(12px);
			text-transform: uppercase;
			color: $bkgMenuHover;	
			margin-bottom: 9px;
		}
		.comment-content {
			font-size: em(12px);
			margin: 5px 0 10px;
		}		
	}	
}

/* CATEGORY CENTER BLOCK*/
.category #center_column {
	
	.content_scene_cat {
		border: none;
	}

	.content_scene_cat_bg {
		padding: 0;
		background-color: transparent!important;

		img {
			width: 100%;
			margin-bottom: 20px;
		}

		.category-name {
			float: left;
			width: 100%;
			color: $colorHeading;
			@include fontStyle_6();
			text-transform: uppercase;
			font-weight: normal;
			font-size: 20px;
			line-height: 1;
			margin-bottom: 20px;
		}

		.cat_desc {
			color: $colorText;

		}
	}

	.page-heading {
		display: none;
	}

	#subcategories {
		border: none;
		position: relative;
		margin-bottom: 60px;
		margin-left: -15px;
		margin-right: -15px;
		@media (max-width: 767px) {
			margin-bottom: 30px;
		}

		.owl-controls {
			position: absolute;
			right: 15px;
			top: -45px;
		}

		.subcategory-name {
			font-family: $fontHeading;
			font-weight: normal;
		}

		.subcategory-heading {
			@include fontStyle_5();
			font-weight: normal;
			padding: 0 15px;
			font-size: 15px;
			text-transform: uppercase;
		}

		ul {
			margin: 0;
			

			li {
				margin: 0;
				height: auto;
				padding: 0px 15px;
				width: 100%;

				h5 {
					position: absolute;
					width: 100%;
					height: 0;
					left: 0;
					top: 0;	
					padding: 0 15px;
								    
				    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
				    filter: alpha(opacity=50);
				    opacity: 0.5;				    
				    overflow: hidden;
				    -webkit-transform: rotate(-33.5deg) translate(-112px, 166px);
				    -moz-transform: rotate(-33.5deg) translate(-112px, 166px);
				    -o-transform: rotate(-33.5deg) translate(-112px, 166px);
				    -ms-transform: rotate(-33.5deg) translate(-112px, 166px);
				    transform: rotate(-33.5deg) translate(-112px, 166px);
				    -webkit-transform-origin: 0% 100%;
				    -moz-transform-origin: 0% 100%;
				    -o-transform-origin: 0% 100%;
				    -ms-transform-origin: 0% 100%;
				    transform-origin: 0% 100%;
				    -webkit-transition: all 0.4s ease-in-out 0.3s;
				    -moz-transition: all 0.4s ease-in-out 0.3s;
				    -o-transition: all 0.4s ease-in-out 0.3s;
				    transition: all 0.4s ease-in-out 0.3s;

					a {
						color: #fff;
						text-transform: uppercase;
						display: block;
						background: $colortheme1;							
					}
				}

				&:hover {

					.subcategory-image {
						&:before, &:after {
							-webkit-transition-delay: 0s;
						    -moz-transition-delay: 0s;
						    -o-transition-delay: 0s;
						    transition-delay: 0s;
						}
						&:before{
							-webkit-transform: rotate(56.5deg) translateX(1px);
						    -moz-transform: rotate(56.5deg) translateX(1px);
						    -o-transform: rotate(56.5deg) translateX(1px);
						    -ms-transform: rotate(56.5deg) translateX(1px);
						    transform: rotate(56.5deg) translateX(1px);
						    z-index: 1;
						}
						&:after{
							-webkit-transform: rotate(56.5deg) translateX(-1px);
						    -moz-transform: rotate(56.5deg) translateX(-1px);
						    -o-transform: rotate(56.5deg) translateX(-1px);
						    -ms-transform: rotate(56.5deg) translateX(-1px);
						    transform: rotate(56.5deg) translateX(-1px);
						}
					}
					h5 {
						 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
					    filter: alpha(opacity=90);
					    opacity: 0.9;
					    top: 40%;
					    -webkit-transform: rotate(0deg) translate(0, 0);
					    -moz-transform: rotate(0deg) translate(0, 0);
					    -o-transform: rotate(0deg) translate(0, 0);
					    -ms-transform: rotate(0deg) translate(0, 0);
					    transform: rotate(0deg) translate(0, 0);
					    height: auto;
					    z-index: 2;
					}
				}
			}
		}

		.subcategory-image {
			padding: 0;
			position: relative;
			overflow: hidden;
			a {
				border: none;
				padding: 0;
			}

			img {
				width: 100%;
				height: auto;				
			}

			&:before, &:after {
				// content: "";
				// width: 100%;
				// height: 100%;
				// background: rgba(0,0,0,0.3);
				// position: absolute;
				// left: -100%;
				// top: -100%;
				// -webkit-transition: all 0.35s ease-out;
				// -o-transition: all 0.35s ease-out;
				// transition: all 0.35s ease-out;
				// z-index: 2;
				// opacity: 0;
				content: "";
				position: absolute;
				top: 9%;
				left: -22px;				
			    height: 150%;
			    overflow: hidden;
			    width: 100%;
			    background: rgba(48,38,24,0.60);
			    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
			    filter: alpha(opacity=100);
			    opacity: 1;
			    -webkit-transition: all 0.3s ease-in-out 0.6s;
			    -moz-transition: all 0.3s ease-in-out 0.6s;
			    -o-transition: all 0.3s ease-in-out 0.6s;
			    transition: all 0.3s ease-in-out 0.6s;
			}

			&:after {
				top: auto;
			    bottom: 9%;
			    -webkit-transform: rotate(56.5deg) translateX(180px);
			    -moz-transform: rotate(56.5deg) translateX(180px);
			    -o-transform: rotate(56.5deg) translateX(180px);
			    -ms-transform: rotate(56.5deg) translateX(180px);
			    transform: rotate(56.5deg) translateX(180px);
			    -webkit-transform-origin: 0% 100%;
			    -moz-transform-origin: 0% 100%;
			    -o-transform-origin: 0% 100%;
			    -ms-transform-origin: 0% 100%;
			    transform-origin: 0% 100%;
			}
			&:before{
				left: auto;
			    right: -22px;
			    -webkit-transform: rotate(56.5deg) translateX(-180px);
			    -moz-transform: rotate(56.5deg) translateX(-180px);
			    -o-transform: rotate(56.5deg) translateX(-180px);
			    -ms-transform: rotate(56.5deg) translateX(-180px);
			    transform: rotate(56.5deg) translateX(-180px);
			    -webkit-transform-origin: 100% 0%;
			    -moz-transform-origin: 100% 0%;
			    -o-transform-origin: 100% 0%;
			    -ms-transform-origin: 100% 0%;
			    transform-origin: 100% 0%;
			}

		}
	}
}

/* LAYER SLIDER */
.layered_slider_container {
	width: 89%;
	position: relative;
	@media (max-width: 1199px) {
		left: -3px;
	}
	@media (max-width: 991px) {
		left: -8px;
	}
	
	.layered_slider {
		background: #eee;
		border: none;
		border-top: 1px solid #e0e0e0;	
		border-left: 1px solid #e0e0e0;	
		border-right: 1px solid #e0e0e0;	
		border-radius: 20px;	
	}

	.ui-widget-header {
		background: none;
		background-color: $bkgMenu;
		margin-top: -1px;
	}	

	.ui-slider-handle {
		width: 24px;
		height: 24px;
		border: none;
		background: none;
		background: url('../images/thumb-slider.png')no-repeat center;
		top: 50%;
		margin-top: -12px;
	}	
}

/* COLOR OPTION */
#layered_form {		
	div div ul{
		max-height: none;
		overflow-x: visible;

		li{
			.color-option {
				border-color: #eee;
				width: 12px;
				height: 12px;
				position: relative;
				float: left;
				margin-right: 10px !important;				
				margin-top: 3px;
			}
			// &:after{
			// 	top: 4px;
			// }
		}
	}
}

/* CONTENT SORT FILTER BAR */
.content_sortPagiBar {
	margin-bottom: 40px;
	@media (max-width: 767px) {
		margin-bottom: 10px;
	}

	div.selector {
		display: inline-block;
		width: 150px !important;
	}

	#productsSortForm, .nbrItemPage {
		margin-right: 20px;
		display: inline-block;
		float: left;
		@media (max-width: 767px) {
			width: 50%;
			margin-right: 0 !important;
			.selector {
				width: 100% !important;
				select {
					width: 100%;
					max-width: 100% !important;
				}
			}
		}
	}
	#productsSortForm {
		@media (max-width: 767px) {
			padding-right: 15px;
		}
	}
	.nbrItemPage {
		@media (max-width: 767px) {
			padding-left: 15px;
			.clearfix {
				>span {
					padding-left: 0!important;
				}
			}
		}
		.clearfix {
			>span {
				padding-left: 12px;
			}
		}
	}


	.sortPagiBar {
		border: none;
	}

	
	
	label {
		text-transform: uppercase;
		@include fontStyle_2();
		color: $colorHeading;
		padding-top: 8px;
		padding-right: 10px;
		font-weight: normal;
		@media (max-width: 767px) {
			width: 100%;
			text-align: left;
		}
	}

	.icon-grid-list {
		float: right;
		@media (max-width: 767px) {
			float: none;
			width: 100%;
			padding-top: 10px;
			text-align: left;
		}

		ul {
			margin: 0;
			@media (max-width: 767px) {
				display: block !important;
				float: none;
			}
		}

		.display-title {
			text-transform: uppercase;
			margin-top: 5px;
			font-weight: normal !important;
			@include fontStyle_2();
			@media (max-width: 767px) {
				width: 100%;
				text-align: left;
				margin-bottom: 5px;
			}
		}

		li {
			text-transform: uppercase;

			a {
				font-family: $fontHeading;
				font-size: 13px;
				color: $colorHeading;

				i {
					display: inline-block;
					font-size: 14px;
					color: $colorHeading;
					margin-right: 6px;
					position: relative;
					top: 1px;
				}
			}
		}

		#grid, #list { 
			height: 35px;
			padding: 0 10px;
			border: 1px solid #eee;
			cursor: pointer;
			padding-top: 4px;

			&:hover, &.selected{
				border-color: $bkgMenu;
				background: $bkgMenu;

				a {
					color: #fff;

					i {
						color: #fff;
					}
				} 
			}
		}

		#grid {
			margin-right: 15px;
		}
	}	

	.top-pagination-content {
		border-top: 1px solid #eee;
		margin-top: 10px;
		padding-top: 20px;
		@media (max-width: 767px) {
			margin-top: 20px;
			padding-top: 20px;
			.product-count {
				
			}
		}
	}
}

.search {
	.content_sortPagiBar {
		label {
			@media (max-width: 991px) and (min-width: 768px) {
				display: block;
				float: none !important;
				text-align: left;
			}
		}
		.compare-form {
			@media (max-width: 991px) and (min-width: 768px) {
				margin-top: 30px;
			}
			@media (max-width: 767px) {
				float: none;
				text-align: left;
				margin-bottom: 10px;
			}
		}
	}
}
.category {
	.content_sortPagiBar {
		.icon-grid-list {
			@media (max-width: 991px) and (min-width: 768px) {
				clear: both;
				float: left;
				margin-top: 10px;
				.display-title {
					text-align: left;
					min-width: 54px;
				}
			}	
		}
	}
}

/* BOTTOM PAGING BLOCK*/
.bottom-pagination-content {
	border: none;

	.product-count, .showall {
		display: none!important;
	}

	div.pagination {
		float: none;
		width: 100%;

		ul {
			width: 100%;
			text-align: center;
			position: relative;

			li {
				float: none;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;

				&:not(.pagination_nav){
					width: 40px;
					height: 40px;
					border: 1px solid #eee;	
					margin: 0 3px;

					a,span {
						width: 100%;
						height: 100%;
						background: none;
						border: none;
						@include fontStyle_6();
						color: $colorHeading;
						line-height: 36px;
						font-weight: normal;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}

					&.active, &:hover {

						a,span {
							background: $bkgMenu;
							border-color: $bkgMenu;
							color: #fff;
						}
					}
				}

				&.pagination_previous, &.pagination_next {
					min-width: 130px;
					height: 40px;
					border: 1px solid #eee;	
					position: absolute;
					top: 0;

					&.disabled {
						opacity: 0.6;
					}

					a, span {
						float: none;
						width: 100%;
						height: 100%;
						color: $colorHeading;
						@include fontStyle_3();
						position: relative;
						padding: 0;
						line-height: 38px;

						i {
							width: 38px;
							height: 38px;
							background: #eee;	
							text-align: center;
							font-size: 14px;
							line-height: 38px;
							-webkit-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}
					}

					&:not(.disabled):hover {
						border-color: $bkgMenu;

						a, span {
							color: $bkgMenu;
						}

						i {
							background: $bkgMenu;
							color: #fff;
						}
					}
				}

				&.pagination_previous {
					left: 0;

					a, span {
						text-align: right;
						padding-right: 27px;

						i {
							float: left;

							&:before {
								content: "\f060";	
							}
						}
					}
					
				}

				&.pagination_next {
					right: 0;
					
					a, span {
						text-align: left;
						padding-left: 27px;

						i {
							float: right; 

							&:before {
								content: "\f061";
							}
						}
					}
					
				}

				@media (max-width: 767px) {
					&.pagination_previous, &.pagination_next {
						min-width: 0;
						width: 40px;
						b{
							display: none;
						}
						a,span {
							padding: 0; 
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	} 
}

/* PRODUCT LAYOUT */
.columns-container ul.product_list {
	margin-left: 0;
	margin-right: 0;
	@include clearfix();
	border: 1px solid #eee;

	.button-container {
		text-align: center;
		margin-left: -50px;
		margin-right: -50px; 
	}

	.functional-buttons {
		display: inline-block;
		background: none;
		width: auto; 

		.wishlist, .ajax_add_to_cart_button, .compare {
			display: inline-block;
			float: left;
			margin: 0 5px;
			width: auto;
			vertical-align: top;
		}

		.wishlist {
			border-right: none; 
		}
		
		.addToWishlist,	.ajax_add_to_cart_button, .add_to_compare {
			width: 35px;
			height: 35px;
			display: inline-block;
			float: left;
			font-size: 12px; 

			&:before {
				margin-right: 0;
				
				line-height: 33px;
			}
		}
		.addToWishlist, .add_to_compare {
			&:hover {
				width: 35px !important;
			}
		}
	}

	.color-list-container {
		margin-bottom: 10px; 

		ul {

			li {
				width: 20px;
				height: 20px;	

				a {
					width: 100%;
					height: 100%;
					margin: 0;
					position: relative;

					&:after {
						content: "";
						width: 14px;
						height: 14px;
						border: 4px solid #fff; 
						position: absolute;
						left: 2px;
						top: 2px;
						display: block;  
					}
				}
			}
		}
	}

	.availability span {
		background-color: transparent;
		border: none;	
		color: $colortheme1;
	}

	>li {
		padding-left: 0;
		padding-right: 0;

		.product-container {

			.product-name {
				text-align: center;
			}

			h5 {
				font-size: em(12px);
				margin-bottom: 0;
				
				a {
					text-transform: uppercase;
					margin-bottom: 0;
					font-size: inherit;
					overflow: hidden;
					-ms-text-overflow: ellipsis;
					text-overflow: ellipsis;
					white-space: nowrap; 
				}
			}

			.content_price {

				.product-price {
					font-size: em(10px);
					padding: 0 5px;	
				}	
			}
		}
	}

	&.grid {

		>li {
			border-right: 1px solid #eee;
			border-top: 1px solid #eee;
			@media (max-width: 991px) {
				overflow: hidden;
			}

			.product-container {
				.ajax_add_to_cart_button{
					margin: 0 5px;
				}
				@media (max-width: 991px) {
					
					.button-container {
						height: 59px;
					}
					.left-block {
						
					}
					.right-block {
						.addToWishlist,	.ajax_add_to_cart_button, .add_to_compare {
							-webkit-animation-name: none;
  							animation-name: none; 
						}								
					}
				}

				&:hover {
					@media (max-width: 767px) {
						.product-image-container {
							&:before {
								display: none;
							}
						}						
					}
				}
			}

			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				border-top: none;
			}

			&:nth-child(3n) {
				border-right: none;
			}

			@media(max-width: 991px) {
				&:nth-child(3n) {
					border-right: 1px solid #eee;
				}
				&:nth-child(3n+1) {
					clear: none;
				}
				&:nth-child(2n) {
					border-right: none;
				}
				&:nth-child(3) {
					border-top: 1px solid #eee;
				}	
			}

			@media (max-width: 767px) and (min-width: 480){
				
			}
			@media (max-width: 479px) {
				border-right: none;
				&:nth-child(2), &:nth-child(3) {
					border-top: 1px solid #eee;
				}
				&:nth-child(3n) {
					border-right: none;
				}
			}
		}		
	}

	&.list {
		> li {
			width: 100%;
			border-bottom: 1px solid #eee;
			//padding: 20px 10px 20px 15px;
			@include clearfix();

			&:last-child {
				border: none;
			}

			.product-container {
				float: left;
				width: 100%;

				.left-block {
					width: 26%;
					float: left;

					@media (max-width: 991px) {
						width: 35%;
					}
					@media (max-width: 767px) {
						width: 40%;
					}
					.product-image-container {
						padding: 20px 0 20px 0;
					}
					.new-box {
						top: 10px;
						left: 10px;
					}

					.quick-view {
						
					}
				}

				.right-block {
					width: 74%;
					float: left;
					padding: 20px 10px 20px 30px;
					@media (max-width: 991px) {
						width: 65%;
					}
					@media (max-width: 767px) {
						width: 60%;
					}

					.product-name {
						text-align: left;
						padding-right: 50px;
						white-space: normal;
						line-height: 1.2;
						@media (max-width: 767px) {
							padding-right: 0;
						}
					}

					.reviews-container {
						min-height: 0;
					}

					.price-percent-reduction {
						display: none;
					}

					.comments_note {
						text-align: left;
					}

					.content_price{
						height: 0;
						margin-top: -24px;
						padding-bottom: 24px;

						.product-price {
							display: block;
							text-align: right;
						}

						@media (max-width: 767px) {
							margin-top: 0;
							height: auto;
							.product-price {
								text-align: left;
								padding-left: 0;
							}
						}
					}

					.lnk_view, 
					.product-flags {
						display: none;
					}

					.addToWishlist,	.ajax_add_to_cart_button, .add_to_compare {
						@media (max-width: 420px) {
							width: 30px;
							height: 30px;
							&:before {
								line-height: 28px;
								font-size: 13px;
							}
						}	
					}

					.button-container {
						margin-left: 0;
						margin-right: 0;
						text-align: left;

						.wishlist, .ajax_add_to_cart_button, .compare {
							margin-left: 0;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}
