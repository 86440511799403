$baseFontSize: 13px;
$fontHeading: 'Montserrat', sans-serif;
$fontText: 'Open Sans', sans-serif;
$fontTextArt: 'Playfair Display', serif;
$fonticont: "FontAweSome";
$colorText: #333333;
$colorText1: #ffffff;
$colorText2: #aaaaaa;
$colorHeading: #333333;
$colorText2: #aaaaaa;
$colorTopNav__Currency: #a91111;
$bkgNav: #f9f9f9;
$bkgMenu: #ff9000;
$bkgMenuHover: #333333;
$bkgFooter: #f9f9f9;
$colortheme: #333;
$colortheme1:#ff9000;
$colortheme2: #fff;
$colortheme3: #eee;
$colortheme4:#ff9d1d;
$colortheme5:rgba(255,144,0,0.90);
// // /*----------------Color set 2----------------------*/
// $bkgMenu: #70AF1A;
// $colortheme1:#70AF1A;
// $colortheme4:#8ACF2B;
// $colortheme5:rgba(112,175,26,0.9);
// // // /*----------------Color set 3----------------------*/
// $bkgMenu: #00A1D9;
// $colortheme1:#00A1D9;
// $colortheme4:#26BDF2;
// $colortheme5:rgba(0,161,217,0.9);
// // // /*----------------Color set 4----------------------*/
// $bkgMenu: #E03628;
// $colortheme1:#E03628;
// $colortheme4:#EC584C;
// $colortheme5:rgba(224,54,40,0.9);