/*=============================== CSS FOR BUTTONS ======================================================*/
/*PRODUCT FUNCTIONAL BUTTON*/
.fncBtn {
	color: $colortheme1;
	font-size: em(10px);
	padding: 0;
	text-transform: uppercase;
	border: 1px solid $colortheme1;	
	background: #fff;
	overflow: hidden;
	text-align: center;
	font-family: $fontHeading;
	font-weight: normal;
	white-space: normal;
	position: relative;
	min-width: 0;

	&:before {
		color: inherit;
		width: auto;
		display: block;
	}

	&:hover {
		background: $bkgMenu;
		color: #fff;
		@media (min-width: 992px) {
			width: auto!important;	
			padding: 0 15px;

			&:before {
				//padding-right: 6px;
				display: inline-block;
			}
		}
	}
}
.fncBtn1 {	
	background: #ededed;
	border: none;
	height: 30px;
	line-height: 30px;
	width: 100%;
	&:hover{
		background: $bkgMenu;
		color: #fff;
	}
}
.functional-buttons {
	
	.addToWishlist,	.ajax_add_to_cart_button, .add_to_compare {
		@extend .fncBtn;
	}

	.addToWishlist {
		&:before {
			content: "\f004";
			font-family: "FontAweSome";
			font-size: em(14px);
			line-height: 33px;	
		}
		&:hover {	
			padding: 0;
			&:before {
				padding-right: 0;
				display: block;
			}	
		}
		&.checked {
			&:before {
				content: "\f21e";
				font-family: "FontAweSome";
				font-size: em(14px);
				line-height: 33px;	 
			}	
		}	
	}

	.ajax_add_to_cart_button {
		&:before {
			content: "\f07a";
			font-family: "FontAweSome"; 
			font-size: em(14px);
			line-height: 33px;
			
		}	
		&:hover{
			&:before {
				padding-right: 6px;
				display: inline-block;
			}
		}
		@media (max-width: 767px) {
			font-size: 0;
				&:hover{
					&:before {
						padding-right: 0;						
					}
			}
		}
		@media (max-width: 991px) and (min-width: 768px) {
			font-size: 0;
				&:hover{
					&:before {
						padding-right: 0;						
					}
			}
		}
	}

	.add_to_compare {
		&:before {
			content: "\f14e";
			font-family: "FontAweSome";
			font-size: em(14px);
			line-height: 33px;
			
		}
		&:hover {	
			padding: 0;
			&:before {
				padding-right: 0;
				display: block;
			}	
		}
		&.checked {
			&:before {
				content: "\f00c";
				font-family: "FontAweSome";
				font-size: em(14px);
				line-height: 33px;
			}	
		}
	}
}

/*ADD TO CART BUTTON*/
.btn-cart {
	@extend .btn-style-1;
	font-weight: normal;

	&:before {
		content: "\f07a";
		font-family: "FontAweSome";
		font-size: 18px;
		color: inherit;
		margin-right: 10px;
		display: inline-block;
	}
}

/*GENERAL BUTTON*/
.btn-style-1 {
	@include fontStyle_3();
	text-align: center;
	display: inline-block;
	text-transform: uppercase;
	background: none;
	background: $bkgMenu;
	font-weight: normal;
	color: #fff;
	min-width: 100px;
	padding: 0 15px;

	&:hover {
		color: #fff;
		background: $bkgMenuHover;
	}
}

.btn-style-2 {
	@include fontStyle_3();
	text-align: center;
	display: inline-block;
	text-transform: uppercase;
	background: none;
	background: $bkgMenu;
	font-weight: normal;
	color: #fff;
	min-width: 100px;
	padding: 0 15px;

	&:hover {
		color: #fff;
		background: $colortheme;
	}
}