/*=========================================== CSS FOR FOOTER ===========================================================*/
.bc-top-footer {
	@include clearfix();	

	ul {
		margin: 0;

		li {
			padding: 48px 43px;
			color: #fff;
			@media (max-width: 1199px) {
				padding: 43px 40px;
			}

			&:nth-child(odd) {
				background: $bkgMenu;
			}

			&:nth-child(even) {
				background: $colortheme4;
			}

			.icon {
				font-size: em(24px);
				float: left;
				margin-right: 20px;
				margin-top: 5px;
			}

			h4 {
				text-transform: uppercase;
				margin-bottom: 8px;
			}

			p {
				font-family: $fontTextArt;
				font-style: italic;
				color: $colorText1;
			}

			@media (max-width:1199px) {
				padding: 38px 30px;
				white-space: nowrap;
				h4 {
					margin-bottom: 5px;
				}
			}

			@media (max-width: 991px) {
				padding: 20px 15px;
				white-space: nowrap;
				h4 {
					margin-bottom: 3px;
				}
				.icon {
					margin-right: 10px;
				}
			}
		}
	}
}

.footer-container {
	background: none;
	background-color: $bkgFooter;
	color: #000;
	padding-top: 54px;

	@media (max-width: 767px) {
		padding-top: 30px;
	}

	.container {
		padding-bottom: 20px;
		@media (max-width: 767px) {
			padding-bottom: 20px;
		}
	}

	#footer {
		@media (max-width: 991px) {
			padding-left: 15px;
			padding-right: 15px;
		}	
	}

	.footer-line {
		height: 1px;
		background: #ededed;	
		width: calc(100% - 30px);
		margin: 40px auto 40px;
	}

	#newsletter_block_left {
		width: 75%;
		display: inline-block;
		float: left;

		.news_logo {
			width: 33.333333%;
			float: left; 
			padding-right: 15px;
			padding-top: 55px;

			@media (max-width: 767px) {
				width: 100%;
				padding: 0;
				text-align: center;
				padding-bottom: 20px;
			}
		}

		.news_content {
			width: 66.666666%;
			float: left;
			background: #f2f4f3;
			padding: 47px 33px;

			@media (max-width: 991px) {
				padding: 30px 20px; 
			}
			@media (max-width: 767px) {
				width: 100%;
				margin-bottom: 10px;
			}

			h4 {
				@include fontStyle_2();
				color: $colorHeading; 
				text-transform: uppercase;
				line-height: 1.4;
				width: 21%;
				padding: 0;
				padding-right: 10px;
				margin: -3px 0 0;
				span {
					font-family: $fontTextArt;
					font-size: em(20px);	
					color: $colorHeading;
					font-style: italic;
					text-transform: none;
				}
				@media (max-width: 991px) {
					width: 100%;
					span {
						display: block;
					}
				}
				@media (max-width: 767px) {
					width: 100%;
					padding-bottom: 15px;
					text-align: center;
					span {
						display: block;
					}
				}
			}

			.block_content {
				@media (max-width: 991px) {
					clear: both;
					margin-bottom: 0;
				}	
			}

			.form-group {
				.button-small {
					color: #fff;
					@include fontStyle_1();
					min-width: 0;
					//padding: 0 20px !important;
				}
			}
		}

	}

	#social_block {
		width: 25%;
		float: left;

		ul {
			@include clearfix();
			@media (max-width: 767px) {
				text-align: center;
			}

		}

		h4 {
			display: none;
		}

		li {
			width: 40px;
			height: 40px;			
			margin-left: 20px;
			border: 1px solid #dedede;
			@media (max-width: 1199px) {
				margin-left: 15px;
			} 
			@media (max-width: 991px) {
				margin-left: 5px;
				width: 30px;
				height: 30px;
			}  
			@media (max-width: 767px) {
				width: 40px;
				height: 40px;
				margin: 0 5px;
				display: inline-block;
				float: none!important;
			} 

			&:hover {
				background: $bkgMenu;  
				color: #fff; 
				border-color: $bkgMenu;;
			}

			a {
				display: block;
				width: 100%;
				height: 100%;
				color: #aaa;
				padding-top: 6px;

				@media (max-width: 991px) {
					padding-top: 2px;
				}
				@media (max-width: 767px) {
					padding-top: 6px;
				}

				&:hover {
					color: #fff;
				}
			}
		}
	} 

	.footer-block {

		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px; 
		}

		.links_footer_left, .links_footer_right {
			width: 50%;
			float: left;

			li {
				&:last-child a {
					padding-bottom: 0;
				}
				a {
					font-weight: normal;
					&:hover {
						color: $bkgMenu;
					}	
				}
			}
		}

		&#block_various_links_footer {
			a {
				text-transform: uppercase;
				font-size: em(11px);
				padding-bottom: 17px;
				display: block;
			}
		}
		
	}

	.bc-filterproduct {
		@media (max-width: 767px) {
			clear: both;
		}
		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px;
		}

		.product-container {
			@include clearfix();
			margin-bottom: 22px;

			.left-block {
				width: 70px;
				float: left;
				margin-right: 20px;	
				position: relative;

				.product_img_link:before {
					@include hover-screen();
					background: $colortheme5;				
				}

				img {
					width: 100%;
				}
				&:hover{
					
				}
			}

			.right-block {
				width: calc(100% - 90px);
				float: left; 
			}

			.product-name {
				text-align: left;
				margin-top: 0;
				margin-bottom: 5px;
			}	

			.content_price {

				.product-price	{
					color: $bkgMenu;
				}
			}

			.stock-availability {
				display: none;
			}

			.functional-buttons {
				.quick-view-wrapper-mobile {
					display: none;
				}
				.quick-view {
					@include general-quick-view();					
					span {
						width: 20px;
						height: 20px;
						line-height: 20px;
						font-size: 0;
						background: none;
						box-shadow: none;
						padding: 0;
						&:before {							
							content: "\f002";
						}
					}
				}	
			}
			&:hover {
				.left-block {
					.product_img_link:before {
						@include hover-screen-show();
					}	
				}
				.functional-buttons {
					.quick-view {
						@include general-quick-view-show();
					}	
				}	
			}
		}	
	}

	.bc_tags_block {
		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px;
		}

		a {
			text-transform: uppercase;
			font-size: em(11px);	
			border: 1px solid #ededed;
			padding: 0 18px;
			float: left;
			margin-right: 10px;
			margin-bottom: 12px;
			min-height: 30px;
			padding-top: 5px;
			@media (max-width: 991px) {
				padding-right: 5px;
				padding-left: 10px;
				padding-right: 10px;
			}

			&:hover {
				background: $colortheme;
				color: #fff;
			}
		}
	}

	#block_contact_infos {
		li {
			padding-left: 0 !important;
			font-family: $fontText;
			color: $colorText;
			font-size: em(11px);
			text-transform: uppercase;
			span {
				text-transform: uppercase;
				font-size: em(11px);
				font-family: $fontHeading;	
				color: $colorText !important; 
				padding-right: 5px;
				float: left;
			}
			i {
				display: none;
			}
		}
		@media (max-width: 767px) {
			clear: both;
			float: left;
		}
	}
}

.bc-bottom-footer {
	background: #f9f9f9; 
	padding: 30px 0;
	border-top: 1px solid #f3f3f3;

	.container {
		@media (max-width: 991px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.copy-right {
		text-transform: uppercase;
		@include fontStyle_2();
		color: #727272; 
		@media (max-width: 776px) {
			text-align: center;
		}
	}

	.bc-bewaer-ft {
		text-align: right;

		a {
			display: inline-block;
		}
		@media (max-width: 776px) {
			text-align: center;
		}
	}
}