.heading-counter {
	margin-bottom: 0;
	font-family: $fontHeading;
	#summary_products_quantity {
		font-size: 14px;
		color: $bkgMenu;
	}
}
/*Step*/
ul.step {
	border-radius: 0;
	margin-top: 25px;
	li {
		&.step_todo {
			background: $bkgMenuHover;
			border: none;
			border-radius: 0;
			span, a {
				border-radius: 0; 
				border: none;
				text-shadow: none;
				color: #fff;
				@include fontStyle_6();
				&:after{
					display: none;
				}
			}	
		}
		&.step_current, &.step_done {
			background: $bkgMenu;
			border: none;
			border-radius: 0;
			a, span {
				text-shadow: none;
				border: none;
				&:after{
					display: none; 
				}
			}

		}
		&.step_done {
			font-style: italic;
			em {
				font-style: italic;
			}
		}
	}
}
/*cart summary table*/
#cart_summary {
	tr {
		td {
			border-color: #eee;
		}
	}
	thead {
		th {
			padding: 15px 20px;
		}
	}
	tbody {
		td {
			padding: 15px 20px;
			&.cart_product {
				padding: 20px;
				img {
					border: none;
				}
			}
			&.cart_avail {
				span {
					@extend .stock-availability;
					border: none;
					background: none;
				}
			}
		}
	}
	tfoot {
		tr {
			background: none;
			td {
				padding: 15px 20px;
			}
		}
	}
	>thead
	{
		>tr{
			>th{
				background: #eee;
				color: $bkgMenuHover;
				text-transform: uppercase;
				font-family: $fontHeading;
				font-weight: normal;
			}
		}
	}
	.price {
		color: $bkgMenu;
		font-family: $fontHeading;
		font-size: em(14px);

		.price-percent-reduction {
			border-radius: 0;
			height: auto;
			width: auto;
			padding: 5px;
			position: relative;
			top: 0;
			right: 0;
		}
	}
	#total_price {
		font-size: (18px);
		color: $bkgMenu;
		font-weight: bold;
	}
	.btn.button-plus, .btn.button-minus {
		color: #fff;
		border: none;
		@media (max-width: 767px) {
			display: inline-block;
			float: left;
		}
		span {
			background: $bkgMenuHover;
		}
		&:hover {
			span {
				background: $bkgMenu;
			}
		}
	}
	.btn.button-plus {
		float: right;
	}	
}
.cart_navigation {
	.standard-checkout,.button-exclusive, .btn {
		height: 40px;
		line-height: 40px;
		padding: 0px 10px;
		margin: 0;
		@media (max-width: 767px) {
			display: block;
			margin-bottom: 10px;
			float: left;
			width: 100%;
		}
		i.right, i.left, i {
			color: inherit;
			font-size: 12px;
			line-height: 1;
			vertical-align: 0px;
			display: none;
		}
		span {
			font-size: em(14px);
		}
	}
	.standard-checkout, [type="submit"] {
		@extend .btn-style-1;	
		border: none;
		border-radius: 0;
		span{
			border: none;
			padding: 0;
		}
	}
	.button-exclusive {
		@extend .btn-style-2;
		border: none;
		border-radius: 0;
		color: #fff;
		
	}
}
#order, #authentication, #address, #identity {
	label {
		margin-bottom: 10px;
	}	
	.form-group {
		margin-top: 20px;
	}
	.checkbox {

		.checker {
			margin-top: 3px;
		}
	}

}
#order {
	.addresses {
		div.checker {
			margin-top: 3px;
		}
		.address_add.submit {
			margin-top: 20px;
		}	
	}
	p.payment_module {
		a {
			border-radius: 0;
			background-color: transparent;
			border-color: #eee;
			&:hover {
				color: $bkgMenu;
				span {
					color: inherit;
				}
				&:after {
					color: inherit;
				}
			}
			background-position: 15px 50%;
		}
	}
}
/*Authentication*/
#authentication {
	.box {
		border: none;
		background: none;
		padding-left: 0;
		padding-right: 0;
	}

	h3 + .required {
		display: none;
	}

	button#SubmitCreate  { 
		@extend .btn-style-2; 
		border: none;
		height: 40px;
		line-height: 40px;
		padding: 0 15px;
		span {
			border: none;
			padding: 0;
		}
	}

	#create-account_form {
		@media (max-width: 767px){
			min-height: 0;
		}
	}

	#login_form {
		button#SubmitLogin	{
			@extend .btn-style-1; 
			border: none;
			height: 40px;
			line-height: 40px;
			padding: 0 15px;
			span {
				border: none;
				padding: 0;
			}	
		}
	}
}
/*Adress*/
#address {
	
	#adress_alias {
		margin-bottom: 40px;
	}

	.footer_links {
		.btn {
			@extend .btn-style-1;
		}
	}

}
/*My account*/
#my-account {
	ul.myaccount-link-list {
		li {
			a {
				border-radius: 0;
				background: none;
				border-color: #eee;
				color:$colorText;
				i {
					
					text-shadow: none;
					border-radius: 0;
					border: none;
					color: inherit;

				}
				span {
					@include fontStyle_7();
					font-weight: normal;
					border: none;
					border-left: 1px solid #eee;
					color: inherit;
					text-shadow: none;
					border-radius: 0;
				}
				&:hover{
					background: $bkgMenu;
					color: #fff;
					border-color: $bkgMenu;  
				}	
			}
		}	
	}	
}
/*Product Compare*/
#products-comparison {
	#product_comparison {
		border-color: #eee;
		.remove {
			a {
				color: $bkgMenuHover;
				&:hover {
					color: $bkgMenu;
				}
			}
		}
		tr {
			td{
				border-color: #eee;
				padding: 20px;
				&.comparison_infos{
					position: relative;
				}
				&.td_empty, &.feature-name {
					font-family: $fontHeading;
					text-transform: uppercase;
				}
				.price-percent-reduction {
					top: 54px;
					margin-top: 15px;
				}
			}
		}
		.product-image-block {
			width: 100%;
			.product_image {
				border: none;
				img {
					width: 100%;
				}
			}	
		}
		h5 {
			min-height: 0;
			padding-bottom: 0;
		}
		.product-name {
			text-transform: uppercase;
			font-size: em(16px);
		}
		.prices-container {
			.product-price {
				font-size: em(14px);
			}
		}
		.button-container {
			a {
				margin: 0 5px;
				span {
					font-size: em(14px);
				}
				&.lnk_view {
					@extend .btn-style-1;
				}
			}	
		}
	}
	.table-responsive {
		margin-top: 30px;
	}
	div.star:after {
		color: $bkgMenu; 	
	}
	div.star.star_on:after {
		color: $bkgMenu;
	}
}

#password {
	.footer_links {
		.button {
			@extend .btn-style-1; 	
		}
	}
}

/*ONESTEP CHECKOUT*/
#order-opc{
	div.checker {
		margin-top: 3px;
	}
	.title_block {
		font-weight: normal;
	}
	div.selector {
		width: 100%;
		span, select {
			width: 100%;
		}
	}
	.opc-wapper {
		margin-left: -15px;
		margin-right: -15px;
	}
	#openLoginFormBlock {
		&:hover {
			color: $bkgMenu;
		}	
	}
	.form-group {
		margin-bottom: 10px;
		.form-control {
			width: 100%;
			max-width: 100%;
		}
	} 
	#opc_new_account,
	#carrier_area,
	.opc_payment_area,
	.opc-account-area  {
		width: 50%;
		float: left;
		padding: 0 15px;

		@media (max-width: 767px) {
			width: 100%;
		}
	}
}
/*OUR STORES*/
#stores {
	.radius-input {
		label {
			padding-top: 5px;	
		}
	}	
}
/*ABOUT US*/
.cms-about-us {
	#center_column {
		ul {
			li {
				color: $bkgMenu;
			}
		}
	}	
}
/*CONTACT*/
#contact {
	.contact-info {
		padding-bottom: 30px;
		.icon {
			margin-right: 10px;
			float: left;
		}
		.contact-info-title {
			display: block;
			text-transform: uppercase;
			@include fontStyle_7();
			color: $bkgMenu;
		}
		.contact-info-subtitle {
			text-transform: uppercase;
			color: $bkgMenuHover;
		}
		@media (max-width: 1199px) {
			.icon {
				display: block;
				float: none;
				text-align: center;
				margin-bottom: 10px;
			}
			.contact-info-title,
			.contact-info-subtitle {
				text-align: center;
				display: block;
			}
		}
	}
	.contact-form-box{
		padding-top: 0;
		background: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		select.form-control{
			max-width: 100%;
		}
		fieldset {
			background: none;
			padding: 0;
			>.clearfix {
				>div:nth-child(1){
					padding-left: 0;
					@media(max-width: 991px) {
						padding-right: 0;
						margin-bottom: 15px;
					}
				}
				>div:nth-child(2){
					padding-right: 0;
					@media(max-width: 991px) {
						padding-left: 0;
					}
				}
			}
		}
		.page-subheading {
			margin-top: 0;
		}
		div.selector {
			width: 100%!important;
		}
		input{
			width: 100%;
			max-width: 100%;
			background: #fff;
		}
		div.uploader {
			height: 35px;
			line-height: 35px;
			input, span {
				height: 35px;
				line-height: 35px;
			}
			span.action {
				@extend .btn-style-1;
				width: auto;
				@media(max-width: 370px) {
					padding: 0 5px;
					min-width: 0;
					
				}
			}
		}
	}
	address {
		.address-title{
			@include fontStyle_6();
			color: $bkgMenu;
			display: block;
			text-align: center;
			text-transform: uppercase;
		}	
	}
	.right-contact{
		margin-bottom: 30px;
		p{
			color: $colorText2;
			line-height: 24px;
		}
	}
	.box-time-contact{
		background: #000 url(../images/bg-contact.jpg) center left no-repeat;
		color: $colorText1;
		font-size: 18px;		
		text-transform: uppercase;
		padding: 35px;
		margin-top: 60px;
		b{
			display: block;
			font-size: 30px;
			color: $colortheme1;
			margin-top: 25px;
		}
	}
}

/*SITEMAP*/
#left_column {
	ul.tree {
		padding-left: 0;
		li {
			padding: 0;
			margin-left: 0;
			background: none;
			border: none;
		}
	}	
}
#sitemap {
	.page-subheading {
		border-bottom: 1px solid #eee;
	}
	.sitemap_block {
		li a:hover {
			font-weight: normal;
			color: $bkgMenu;
		}
	} 
}

/*ORDER HISTORY*/
#history {
 .footable-row-detail-row {
 	line-height: 2.3em;
 	.link-button {
 		margin-left: 10px;
 		&:hover {
 			color: $bkgMenu;
 		}
 	}
 }
 .footer_links 	{
 	li {
 		@media(max-width:370px) {
 			margin-bottom: 10px;
 			margin-left: 0;
 			margin-right: 10px;
 		}
 	}
 }
}

/*WISHLIST*/
.module-blockwishlist-mywishlist {
	#block-history{
		overflow: auto;
	}
	.footer_links {
		li {
			@media(max-width: 350px) {
				clear: both;
				margin-left: 0;
				margin-bottom: 10px;
			}
		}
	}	
}
.cms-about-us{
	p{
		color: $colorText2;
		line-height: 24px !important;
	}
	.block-title{
			h3{
				font-size: 20px !important;
			}
		}
	.box-about{
		margin: 0 0 50px;
		.page-heading{
			font-size: 30px;
		}
	}
	.box-text-about{
		background: #eee;
		padding: 20px;
		margin-bottom: 30px;
		transition: 0.4s;
		h4{
			font-size: 13px;
			text-transform: uppercase; 		
			margin-bottom: 20px;
			line-height: 22px;
			i{
				margin-right: 10px;
				font-size: 18px;
				float: left;
				vertical-align: middle;
				line-height: 22px;
			}
			span{
				overflow: hidden;
				display: block;
			}
		}
		&:hover{
			background: $colortheme;
			h4{
				color: $colortheme2;
			}
		}
	}
	.about-center{
		position: relative;
		border-top: 1px solid #EAEAEA;
		padding-top: 60px;
		margin: 60px 0;
		&:before{
			width: 100%;
			position: absolute;
			left: 0;
			top:0;
			height: 1px;
			background: #dbdbdb;
			//content: "";
		}		
		.team-about{
			text-align: center;
			position: relative;
			h3{
				text-transform: uppercase;
				font-size: 16px !important;
				padding: 0 0 5px !important;
				margin: 0;
			}
			span{
				color: $colorText2;
				font-style: italic;
				display: block;
				margin-bottom: 15px;
			}
			.team-detail{
				background: #fff;
				margin: 0 20px;
				padding: 30px 20px;
				height: 80px;
				overflow: hidden;
				transition: 0.4s;
			}
			&:hover{
				.team-detail{
					position: absolute;
					height: auto;
					bottom: -60px;
					overflow: visible;
					box-shadow: 1px 2px 1px 1px #eee;
				}
				span{
					color: $colortheme1;
				}
			}
			@media (max-width: 767px) {
				margin-bottom: 50px;
			}
		}
		.social-about{
			margin: 20px 0 0;
			padding: 0;
			text-align: center;
			list-style: none;
			li{
				display: inline-block;
				margin: 0 8px;
				a{
					font-size: 0;					
					text-align: center;
					background: $colortheme3;
					color: $colortheme1;
					display: inline-block;
					width: 40px;
					height: 40px;
					line-height: 40px;
					border-radius: 50%;
					i{
						font-size: 14px;
						line-height: 40px;
					}
					&:hover{
						background: $colortheme1;
						color: $colortheme3;
					}
				}
			}			
		}
	}
	.about-bottom{
		border-top: 1px solid #EAEAEA;
		padding-top: 60px;
		.col-sm-6:first-child,.col-sm-6:nth-child(3n+0){
			border-right: 1px dashed rgba(170,170,170,0.30);
		}
		.col-sm-6:first-child,.col-sm-6:nth-child(2){
			border-bottom: 1px dashed rgba(170, 170, 170, 0.3);
		}
		.col-service{
			padding: 30px 0;
			.left-service{
				transition: 0.4s;
				float: left;
				display: inline-block;
				width: 80px;
				height: 80px;
				line-height: 80px;
				font-size: 0;
				margin-right: 20px;
				background: $colortheme1;
				color: $colortheme2;
				border-radius: 50%;
				text-align: center;
				border: 5px solid #fff;
				box-shadow: 0 0 0 1px $colortheme1;
				i{
					font-size: 24px;
					line-height: 70px;
				}
			}
			.right-service{
				overflow: hidden;
				h3{
					text-transform: uppercase;
					margin-bottom: 20px !important;
					position: relative;
					&:before{
						content: "";
						height: 2px;
						width: 41px;
						background: $colortheme1;
						position: absolute;
						left: 0;
						bottom:0;
					}
				}
			}
			&:hover{
				.left-service{
					cursor: pointer;
					background: $colortheme;
					box-shadow: 0 0 0 1px #333;
				}
			}
		}
	}
}