#index .tab-content{
	margin: 0;
}
.block-title{
	text-align: center;
	margin: 0 0 50px;
	span{
		
	}
	h3{
		font-size: 24px;
		font-weight: bold;		
		text-transform: uppercase;
		margin: 0;
		padding: 0 0 10px;
	}
	p{		
		color: $colorText2;
		font-style: italic;
	}
}
.bc-top-home {
	padding-bottom: 60px;
	@media (max-width: 767px) {
		padding-bottom: 30px;
	}
}
.bc-top-center{
	background: $bkgNav;
	padding: 40px 0 60px;
	.block-content{
		.out-prod-filter{
			background: #fff;
			border: 1px solid #eee;
			margin: 0 15px;
			@include clearfix();
			.bc-filter-product{
				.tab-content{
					border: none;
					@media (max-width: 767px) {
						border: 1px solid #eee;
					}
				}
				@include clearfix();
				.item{
					float: left;
					width: 33.3%;
					@media (max-width: 767px) {
						width: 50%;
						//margin-bottom: 30px;
					}
					@media (max-width: 480px) {
						width: 100%;
						//margin-bottom: 30px;
					}
					@media (max-width: 991px) and (min-width: 768px) {				
						width: 50%;
					}
				}
			}
			.bc-item{
				background: #fff;
				.item .product-container{
					border: none;
				.left-block{
					padding: 0 30px 30px;
					.product-name a{
						font-size: 18px;
					}
					.price.product-price, .old-price.product-price{
						font-size:15px; 
					}
					.comments_note{
						text-align: right;
						position: absolute;
						right: 0;
						bottom: 40px;
					}
					.button-container{
						margin: 30px 0 0;
						.button{
							span{
								font-weight: normal;
								font-size: 12px;								
							}
							&:before{
								content: "\f07a";
								font-family: $fonticont;
								display: inline-block;
								padding-right: 6px;

							}
						}
					}
					@media (max-width: 767px) {
						padding: 0 0 30px;
						.comments_note{
							right: 15px;
						}
					}
				}
				@media (max-width: 767px) {
						padding: 0 15px;	
						border: 1px solid #eee;					
					}
			}
			}
			@media (max-width: 767px) {
				background: none;
				margin: 0;
				border: none;
			}
		}
	}
}
.bc-center-home{
	margin: 60px 0;
	.bc-productwithcategory {
		.tab-content{
			border: none;
			.item{
				border: none;
				@media (max-width: 767px) {
					float: none;
					display: block;
				}
			}
			.owl-item{
				border: 1px solid #eee;
				border-right: none;
				&.last{
					border-right: 1px solid #eee;
				}
			}
			.owl-controls{
				.owl-nav{
					text-align: left;
					.owl-prev,.owl-next{
						position: absolute;
						top: 50%;
						left: -15px;
						margin: 0 !important;
					}
					.owl-next{
						left: auto;
						right: -15px;						
					}
					@media (max-width: 767px) {
						.owl-next{
							right: -10px;	
						}
						.owl-prev{
							left: -10px;
						}
					}
					@media (max-width: 991px) and (min-width: 768px) {
						.owl-next{
							right: 0px;
						}
						.owl-prev{
							left: 0;
						}
					}	
				}
			}
		}
	}	
}
.bc-bottom-home{
	background: $bkgNav;
	margin: 60px 0;
	padding: 40px 0 60px;
	.bc-productwithcategory{
		padding: 0;
	}
	.block-title{
		margin-bottom: 30px;
		border-bottom: 1px solid #eee;
		width: calc(100% - 100px);
		h3{
			text-align: left;
			font-size: 18px;
		}
	}
	.bc-productwithcategory {
		.tab-content{
			.item{
				border: none;
				float: none;
				display: block;
				.product-container{
					background: none;
					margin-bottom: 30px;
					@include clearfix();
					.left-block{
						float: left;
						width: 46%;
						margin-right: 8%;
						.product-image-container{
							padding: 0;
						}
						.button-container{
							margin: 0;
							position: absolute;
							bottom: 0;
							height: 30px;							
							.quick-view{
								position: relative;
								left: auto;
								top: auto;
								transform: none;	
								display: inline-block;	
								height: 30px;						
								span{
									padding: 0;									
									box-shadow: none;
									@extend .fncBtn1;																		
									font-size: 0;
									text-align: center;
									display: inline-block;	

									&:before{
										content: "\f06e";
										font-family: $fonticont;
										line-height: 30px;
										color: $colortheme1;
									}
									&:hover{
										width: 35px !important;
										padding: 0;
										&:before{
											color: #fff
										}
									}
								}
							}
							.functional-buttons {	
								.wishlist, .ajax_add_to_cart_button, .compare ,.quick-view{
									margin: 0;
									width: 33.3%;
								}
								.addToWishlist,	.ajax_add_to_cart_button, .add_to_compare,.quick-view span {
									@extend .fncBtn1;
									visibility: hidden ;	
									transition: none;								
									&:hover{
										width: 100% !important;
									}
								}
								.add_to_compare{
									border-left: 1px solid #a5a5a5;									
								}
								.quick-view span {border-left:1px solid #a5a5a5;}
							}
						}
					}
					.right-block{
						background: none;
						text-align: left;
						width: 46%;
						float: right;
						padding: 0;
						.product-name{
							text-align: left;
						}
						.comments_note{
							text-align: left;
						}
						.button-container{
							text-align: left;
							margin: 0;
							.functional-buttons{
								.ajax_add_to_cart_button{
									width: auto;
									padding: 0 15px;
									margin: 0;	
									font-size: 10px !important;								
									&:before{
										display: inline-block;
										padding-right: 6px;
									}
									@media (max-width: 991px) and (min-width: 768px) {
										padding: 0 5px;
										height: 25px;
										line-height: 25px;
										&:before{
											line-height: inherit;
										}
									}	
								}
							}
						}
					}
					&:hover{					
					.addToWishlist,	 .add_to_compare,.quick-view span {
						animation-name: fadeInDown;
						visibility: visible !important;
						animation-duration: .5s;
						animation-delay: 0s;
						animation-fill-mode: both;						
					}
					.add_to_compare{
						animation-delay:0.2s;
					}
					.quick-view span{
						animation-delay:0.4s;
					}
				}
				}
				
			}
			.owl-controls{
				.owl-prev,.owl-next{
					position: absolute;
					top: -60px;
					right: 0;
					background: $bkgNav;
					&:hover{
						background: $bkgMenu;
					}
				}
				.owl-prev{
					right: 40px;
				}
			}
		}
	}
}
.bc-footer-home{
	.owl-controls{
				.owl-prev,.owl-next{
					position: absolute;
					top: 35%;
					left: -10px;
					margin: 0 !important;
				}
				.owl-next{
					right: -10px;
					left: auto;
				}
				@media (max-width: 767px) {
					.owl-prev,.owl-next{
						top: 30%;

					}
					.owl-next{
						right: -5px;
					}
					.owl-prev{
						border-bottom-left-radius: -10px;
						left: -5px;
					}
				}				
				@media (max-width: 991px) and (min-width: 768px) {
					.owl-prev{
						left: 0;
					}
					.owl-next{
						right: 0;
					}
				}
			}
	@media (max-width: 767px) {
		.content-manufacture-block{
			padding: 0 15px;
		}
	}
}
.bc-home {

	.new-arrival {
		padding-left: 15px;
		padding-right: 15px;
	}

	>.container {
		@media (max-width: 767px) {
			padding: 0;
		}
	}
}

.bc-banner-top {
	padding-bottom: 60px;

	li {
		position: relative;
		display: block;
		.img-wrapper {
			-webkit-transition: all .35s;
			-o-transition: all .35s;
			transition: all .35s;
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			-o-transform: scale(1);
			transform: scale(1);
		}
		.item-wrapper {
			overflow: hidden;
			position: relative;
			display: block;
			@include imghover() ;			
		} 
		&:nth-child(3) {
			.bc-services {
				text-align: right;
			}
		}
		@media (max-width: 767px) {
			margin-bottom: 30px;
		}
		&:hover {
			p, h2 {
				-webkit-transform: scale(0.9);
				-ms-transform: scale(0.9);
				-o-transform: scale(0.9);
				transform: scale(0.9);
			}
			
			.banner-shopnow--btn{
				-webkit-transform: scale(1);
				-ms-transform: scale(1);
				-o-transform: scale(1);
				transform: scale(1);
				opacity: 1;		
			}
			.bc-services {
				padding-top: 0;

			}
			.img-wrapper {
				
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}

	.bc-services {
		text-align: center;
		color: #fff;
		position: absolute;
		z-index: 101;
		left: 0%;
		top: 50%;
		width: 100%;
		padding:50px 35px 0;
		-webkit-transform: translate(0%,-50%);
		-ms-transform: translate(0%,-50%);
		-o-transform: translate(0%,-50%);
		transform: translate(0%,-50%);
		-webkit-transition: all .35s;
		-o-transition: all .35s;
		transition: all .35s;		
		p, h2 {
			-webkit-transition: all .35s;
			-o-transition: all .35s;
			transition: all .35s;
		}
		p {
			font-family: $fontTextArt;
			font-size: em(16px);
			font-style: italic;	
			margin-bottom: 0;
			@media (max-width: 991px) and (min-width: 768px) {
				font-size: 10px;
			}
		}

		h2 {
			text-transform: uppercase;
			line-height: 1.3;
			margin-bottom: em(20px);
			margin-top: 5px;
			@media (max-width: 991px) and (min-width: 768px) {
				font-size: 14px;
			}
		}
	}

	.banner-shopnow--btn {
		@extend .btn-style-2;
		padding: 0 23px;
		min-height: 39px;
		padding-top: 10px;
		opacity: 0;
		-webkit-transform: scale(1.5);
		-ms-transform: scale(1.5);
		-o-transform: scale(1.5);
		transform: scale(1.5);
		background: $colortheme2;
		color: $bkgMenu;
		&:hover{
			background: $bkgMenu;
		color: $colortheme2;
		}
	}
}

/*Vertical Menu*/
.bc-menu-container.menu_vertical {
	padding-left: 0;
	.title-menu {
		min-height: 50px;
		background: $bkgMenu;	
		text-transform: uppercase;	
		@include fontStyle_3();
		color: #fff;
		padding: 15px 20px 0;
		position: relative;

		&::after {
			content: "\f0c9";
			font-family: "FontAweSome";
			color: #fff;
			font-size: 18px;
			display: block;
			position: absolute;
			right: 20px;
			top: 18px;
		}
		&:before{
			content: "";
			position: absolute;
			bottom: -7px;
			left: 20px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 7px 6.5px 0 6.5px;
			border-color: $colortheme1 transparent transparent transparent;
			z-index: 10;
		}
	}

	.mega-menu {
		max-height: inherit;
		padding-left: 0;
		position: relative;
	}

	.menu-content {
		height: auto;
		@include clearfix();

		> li {
			display: block;
			width: 100%;
			line-height: 0;
			background: #fff;
			color: $colorHeading;	
			border: 1px solid #eee;
			border-top: none;
			position: relative;

			&:first-child {
				border-top: 1px solid #eee;
			}

			> a {
				padding: 0 20px 0 35px;
				float: left;
				line-height: 50px;
				height: 50px;
				width: 80%;
				@include fontStyle_4();
				position: static;
				color: $colorHeading;
				position: relative;
				-ms-text-overflow: ellipsis;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding-right: 10px;
				@media (max-width: 991px) {
					height: 40px;
					line-height: 40px;
					padding: 0 10px;
				}

			}

			> .icon-drop-mobile {
				display: inline-block;
				width: 40px;
				height: 51px;
				line-height: 51px; 
				text-align: right;
				float: right;
				padding-right: 20px;
				@media (max-width: 991px) {
					width: 30px;
					line-height: 40px;
					height: 40px;
					padding-right: 10px;
				}

				&::after {
					content: "\f105";
					font-family: "FontAweSome";
					font-size: 18px;
					display: block;
					color: $colorHeading;
					
				}		
			}

			&:hover {
				background: none;	

				> a, >.icon-drop-mobile {
					color: $bkgMenu;

					&:after {
						color: $bkgMenu; 
					}
				}
			}
		}
	}  
}

.index {
	#slider-wrapper {
		padding-top: 0;
		padding-left: 15px;
		overflow: hidden;
		@media (max-width: 767px) {
			padding: 0;
		}    
	}
}

/*General tab content css*/
.tab-content {

	.button-container {
		text-align: center;
		@include clearfix();
		font-size: 0;
		margin-left: -40px;
		margin-right: -40px;
		z-index: 100;
	}

	.functional-buttons {
		display: inline-block;

		.wishlist, .ajax_add_to_cart_button, .compare {
			display: inline-block;
			float: left;
			margin: 0 5px;
		}
		
		.addToWishlist,	.ajax_add_to_cart_button, .add_to_compare {
			width: 35px;
			height: 35px;
			display: inline-block;
			float: left;
		}

		.addToWishlist, .add_to_compare {
			&:hover {
				width: 35px !important;
			}
		}
	}

	.button.ajax_add_to_cart_button {
		vertical-align: top;
	}
}

.bc-filterproduct {
	position: relative;
	@media (max-width: 991px) {
		//padding-left: 15px;
		//padding-right: 15px;
	}
	
	.type-tab {
		.title_block{
			float: left;
			padding: 0 30px;
			h3{
				font-weight: bold;
				margin: 0;
				padding: 0;
				line-height: 50px;
				text-transform: uppercase;
				&:before{
					content: "\f07a";
					font-family: $fonticont;
					float: left;
					margin: 0 10px 0 0;
				}
			}
			@media (max-width: 767px) {
				float: none;
				padding: 0;
			}
			@media (min-width: 991px) {
				width: 30%;
			}
		}

		.nav-tabs  {
			text-align: left;
			padding-right: 223px;
			font-size: 0;
			border: 1px solid #eee;
			border-bottom: none;
			background: #fefefe;
			margin-bottom: 0px;
			@media (max-width: 991px) {
				padding-right: 108px;
			}
			@media (max-width: 767px) {
				padding-right: 0;
				padding-bottom: 42px;				
			}

			li {
				float: none;
				display: inline-block;
				min-width: 167px;
				text-align: center;
				overflow: hidden;
				background: transparent;								
				@media (max-width: 991px) and (min-width: 768px) {
					min-width: 100px;
				}				
				a {
					text-transform: uppercase;
					font-size: em(12px);
					margin: 0;
					border: 1px solid #eee;
					border-right: none;
					border-bottom: none;
					min-height: 50px;
					padding-top: 15px;
					color: $colorText2;
					font-weight: normal; 
					background: $bkgNav;
					&:hover {
						background: none;
						border-bottom: none;
					}
				}

				&:last-child {
					a {
						border-right: 1px solid #eee;
					}
				}

				&:nth-child(odd) {
					a:before {
						content:"\f188";
						display: inline-block;
						margin-right: 5px;
						font-family: "FontAweSome";
						font-size: em(12px);
						color: inherit;
					}
				}

				&:nth-child(even) {
					a:before {
						content:"\f1ba";
						display: inline-block;
						margin-right: 5px;
						font-family: "FontAweSome";
						font-size: em(12px);
						color: inherit;
					}
				}

				&:hover, &.active {
					a{
						color: $bkgMenu;
						background: none;
						//font-weight: bold;	
					}  
				}
			}
		}	
	}

	.tab-content {
		margin: 0 !important;
		border: 1px solid #eeeeee;	
		@include clearfix();
			}
		.item {

			.product-container {				
				border-right: 1px solid #eee;
				border-bottom: 1px solid #eee;
				position: relative;
				top:0;
				//padding-bottom: 139px;
				z-index: auto;
				@media (max-width: 767px) {
					border: 1px solid #eee;
					border-top: none;
					padding: 0 15px;
				}

				.product-name {
					@include fontStyle_2();
					color: $colorHeading;
				}

				&:nth-child(2) {
					border-bottom: 1px solid transparent;
				}
			}
		}

		.owl-item.last {

			.item {

				.product-container	{
					border-right: 1px solid transparent;
					border-left: 1px solid transparent;
				}
			}
		}

		.tab-pane{
			.item {
				visibility: hidden; 
			}
			&.active {
				.item {
					visibility: visible;
					-webkit-backface-visibility: visible !important;
					-ms-backface-visibility: visible !important;
					backface-visibility: visible !important;
					-webkit-animation-name: fadeIn;
					animation-name: fadeIn;
					-webkit-animation-duration: 1s;
					animation-duration: 1s;
					-webkit-animation-fill-mode: both;
					animation-fill-mode: both;
				}	
			}
		}

	.owl-controls {
		position: absolute;
		right: 20px;
		top: -40px;
		@media (max-width: 767px) {
			right: 0;
		}
	}
}

.bc-productwithcategory {
	padding-left: 15px;
	padding-right: 15px;
	.out-prod-filter {
		@include clearfix();				
	}
	.block-content {
		position: relative;
	}
	
	.title_block {
		
	}

	.type-tab {
		position: relative;

		.nav-tabs {
			position: absolute;
			top: -47px;
			right: 0;
			border: none;
			padding-top: 15px;

			>li {
				margin-bottom: 0;

				a {
					@include fontStyle_2();
					text-transform: uppercase;
					color: #fff; 
					padding: 0 15px;
					margin-right: 0;
					border: none;
				}

				&.active {

					a {
						background: transparent;
						border: none;
						font-weight: bold;
					}
				}

				&:hover {
					a {
						background: none;
					}
				}	
			}
		}
	}

	.tab-content {
		margin-top: 0;
		//border: 1px solid #eee;
		border-top: none;		
		@include clearfix();

		.item {
			display: inline-block;
			float: left;
			position: relative;
			border-right: 1px solid #eee;
			border-top: 1px solid #eee;

			&:nth-child(3n+1) {
				clear: both;
			}

			&:nth-child(3n) {
				border-right: none;
			}	

			img {
				width: 100%;
			}

			.product-container {				
				//padding-bottom: 139px;
				@media (max-width: 991px) {
					//padding-bottom: 137px;
				}

				.product-name {
					@include fontStyle_2();
					color: $colorHeading;
				}
			}
		}

		.bc_banner {

			ul {
				@include clearfix();
				margin: 0;
			}
		}
	}

	&.bc-prowithbig {

		.bc-filter-product {
			padding-right: 0;
			@include clearfix();
			@media(max-width:767px) {
				padding-right: 7.5px;
			}
		}

		.tab-content {

			.item {
				width: 33.333333%;
				@media (max-width: 767px) {
					width: 50%;
					&:nth-child(3n+1) {
						clear: none;
					}
					&:nth-child(3n) {
						border-right: 1px solid #eee;
					}
					&:nth-child(2n+1) {
						clear: both;
					}
					&:nth-child(2n) {
						border-right: none;
					}
				}
			}

			.bc_big_product {
				padding-left: 0;
				border-left: 1px solid #eee;
				padding-bottom: 1000px;
				margin-bottom: -1000px;
				@include clearfix();
				@media (max-width: 767px) {
					padding-bottom: 0;
					padding-left: 14px;
					padding-right: 7.5px;
					margin-bottom: 0;
				}

				.left-block {
					padding: 0 30px;
					@include clearfix();
				}

				.item {
					width: 100%;
					border-right: none;
					.product-container {
						padding-bottom: 0;
						@include clearfix();
					}
					@media (max-width: 767px) {
						padding-bottom: 30px;
					}
				}

				.product-name {
					text-align: left;
					margin-bottom: 20px;
					a {
						font-size: em(18px);
					}

					@media (max-width: 767px) {
						margin-bottom: 5px;
					}
				}

				.comments_note {
					clear: both;
					text-align: left;

					+ .button-container {
						margin-top: -45px;
					}
				}

				.product-price {
					margin-left: 0;
					margin-right: 10px;
				}

				.content_price {
					text-align: left;
					display: inline-block;
					float: left;

					.price.product-price, 
					.old-price.product-price {
						@include fontStyle_3();
						display: inline-block;
					}
				}

				.button-container {
					display: inline-block;
					float: right;
					margin-left: 0;
					margin-right: 0;
					@media (max-width: 991px) {
						clear: both;
						float: left;
						margin-top: 10px!important;
					}
				}

				.button.ajax_add_to_cart_button {
					@extend .btn-cart;
					border: none;
					color: #fff;
					padding: 20px 30px;
					height: auto;
					line-height: normal;
					@media (max-width: 991px) {
						padding: 15px 20px;
					}
				}
			}
		}
	}

	&.bc-prowithbanner {

		.tab-content {

			.bc-filter-product:not(.tab-pane) {

				.item {
					width: 25%;
					clear: none;

					&:nth-child(4n+1) {
						clear: both;
					}

					&:nth-child(3n) {
						border-right: 1px solid #eee;
					}

					&:nth-child(4n) {
						border-right: none;
					}

					@media (max-width: 767px) {
						width: 50%;
						&:nth-child(4n+1) {
							clear: none;
						}
						&:nth-child(2n+1) {
							clear: both;
						}
						&:nth-child(2n) {
							border-right: none;
						}
					}
				}
			}

			
		}

		.bc-filter-product {
			padding-left: 0;
			@include clearfix();
			@media (max-width: 767px) {
				padding-left: 7.5px;
			}
		}

		.bc_banner {
			padding-right: 0;
			border-right: 1px solid #eee;
			padding-bottom: 1000px;
			margin-bottom: -1000px;
			@media (max-width: 767px) {
				padding-bottom: 0;
				margin-bottom: 0;
				display: none;
			}

			.bc-content {
				padding: 35px 35px;

				@media (max-width: 1199px) {
					padding: 40px 30px;
				}

				@media (max-width: 991px) {
					padding: 30px 20px;
				}
				
			}

			.item {
				width: 100%;
				padding: 0;
				margin-bottom: 33px;
				border: 1px solid #eee;
				@media (max-width: 1199px) {
					margin-bottom: 41px;
				}

				&:last-child {
					margin-bottom: 0;
				}

				img {
					width: 100%;
					height: auto;
				}
			}
		}	
	}

	&.bc-bestsellerwithcategory {

		.type-tab {
			border: 1px solid #eee;
			overflow: hidden;
			@media (max-width: 767px) {
				border-top: none;
			}

			.nav-tabs {
				position: relative;
				padding-right: 0;
				padding-top: 0;
				border-right: 1px solid #eee;
				padding-bottom: 1000px;
				margin-bottom: -1000px;
				top: 0;
				background-color: #f9f9f9;
				@media (max-width: 767px) {
					padding-bottom: 0;
					margin-bottom: 0;
					border: none;
				}

				>li {
					width: 100%;
					float: left;
					padding: 19px 12px;
					border-bottom: 1px solid #eee;
					&:last-child {
						border-bottom: none;
					}
					@media(max-width: 1199px) {
						padding: 12px 12px;
					}
					@media(max-width: 991px) {
						padding: 20px 12px;
					}
					@media (max-width: 767px) {
						padding: 0;
						border: none;
					}

					a {
						color: $colorHeading;
						padding-top: 25px;
						@media (max-width: 991px) {
							padding-top: 9px;
						}
					}

					&:before {
						width: 64px;
						height: 64px;
						display: inline-block;
						float: left;
						color: #fff;
						font-size: 35px;
						text-align: center;
						line-height: 64px;
						@media (max-width: 991px) {
							width: 30px;
							height: 30px;
							line-height: 30px;
							padding-top: px;
							font-size: 18px;
						}
						@media (max-width: 767px) {
							display: none;
						}
					}

					&:nth-child(1) {
						
						&:before {
							background: #f87f2f;
							margin-right: 14px;
						}
					}

					&:nth-child(2) {
						
						&:before {
							background: #ff3837;
							margin-right: 14px;
						}
					}

					&:nth-child(3) {
						
						&:before {
							background: #115da9;
							margin-right: 14px;
						}
					}

					&:nth-child(4) {
						
						&:before {
							background: #32004b;
							margin-right: 14px;
						}
					}
					&:nth-child(5) {
						
						&:before {
							background: #11a95d;
							margin-right: 14px;
						}
					}

					&:hover, &.active {
						a {
							color: $bkgMenu;
						}
					}
				}
			}
		}

		.tab-content {
			padding-left: 0;
			padding-right: 0;
			border: none;
			margin-bottom: 0!important;

			.bc_banner {
				display: inline-block;
				width: 25%;
				float: left;
				background: #fefefe;
				border-right: 1px solid #eee;
				padding-top: 20px;
				padding-bottom: 1000px;
				margin-bottom: -1000px;
				@media (max-width: 767px) {
					padding-bottom: 0;
					margin-bottom: 0;
					display: none;
				}
			}

			.tab-pane {
				&:before {
					display: none;
				}	
			}

			.bc-filter-product:not(.tab-pane) {
				width: 75%;
				display: inline-block;
				float: left;
				@include clearfix();

				.item {
					width: 33.333333%;
					.reviews-container,
					.button-container {
						display: none;
					}
					.product-container {
						padding-bottom: 55px;
					}
					.right-block {
						padding-top: 10px;
						padding-bottom: 0;
					}
				}

				@media (max-width: 767px) {
					width: 100%;
					.item {
						width: 50%;
						&:nth-child(2n) {
							border-right: none;
						}
						&:nth-child(2n+1) {
							clear: both;
							border-right: 1px solid #eee;
						}
						&:nth-child(3n+1) {
							clear: none;
						}
					}
				}
			}	
		}	
	}
}
/*CSS FOR HOVER PRODUCT ITEM ON HOMEPAGE*/
.new-arrival {

	.type-tab {
		.nav-tabs{
			height: 50px;
			@media (max-width: 767px) {
				border: none;
				padding: 0;
				height: auto;
			}
			li {
				margin-top: -1px;
				@media (max-width: 767px) {				
					text-align: left;
					float: left;
					margin-top: 0;
					a {
						min-height: 0;
						border: none;
						color: $colorText !important;
						border-bottom: 1px solid $colorText2;
					}
					&.active{
						a{
							color: $colorText1 !important;
						}
					}
				}
				
			}
		}
	}

	.product-container { 

		> a {
			display: block;
			margin: -20px -20px 0 -20px;
			padding: 20px 20px 0 20px;
			position: relative;

			&:before {
				@include hover-screen();	
			}
		}

		.quick-view {
			top: 140px;
		}
	}
}

.bc-filter-product {
	> .item {
		position: relative;
		.product-container {	
			> a {
				display: block;
				margin: -14px -10px 0 -10px; 
				padding: 14px 10px 0 10px;
				position: relative;
				&:before{
					@include hover-screen();		
				}
			}

			
		}

		.quick-view {
			top: 110px;
		}
	}
}


